<template>
  <v-container>
    <SessionComplete />
    <SessionCreditsFooter />
  </v-container>
</template>

<script>
import { projectCustomComponent } from '@utils/project.js'
export default {
  page: {
    title: 'Sessione Conclusa!',
  },
  components: {
    SessionComplete: () => projectCustomComponent('SessionComplete.vue'),

    SessionCreditsFooter: () =>
      projectCustomComponent('SessionCreditsFooter.vue'),
  },
  props: ['sid', 'project'],
}
</script>
